/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";



export default function Ecommerce() {
    return (
        <>
            <IndexNavbar1 fixed />
            <section className="header relative pt-16 items-center flex ">
            </section>
            {/* E-COMMERCE*/}

            <div className="w-full lg:w-12/12 md:w-12/12 lg:pt-12 md:pb-12 md:block hidden">
                <div className="flex flex-wrap justify-center lg:px-32">
                    <div className="lg:w-6/12 md:w-12/12">
                        <div className="flex flex-wrap  ">
                            <div className=" md:py-10 md:mt-10 lg:px-0 md:px-12 lg:pl-10">
                                <h1 className="texthome py-2 text-4xl">
                                    Serving Our Customers With Custom <span className="font-extrabold text-blue pb-4">E-Commerce</span> Website Designs
                                </h1>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Our dedicated team of professional developers follows the project development
                                    and management process to customize and develop your E-commerce website that adapts
                                    to your business needs.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    eCommerce websites are in trend today due to people’s demands and preferences.
                                    Ecommerce platforms offer products & services to customers in the virtual environment.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Elegant Carve is one of the renowned eCommerce website development companies in Bangalore
                                    that offer the finest strategies for delivering nothing but the best e-commerce solutions
                                    to our esteemed clients.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    The vision of our company is to provide world-class digital services and support for
                                    highlighting the specialties and products to niche audiences from all across the globe.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/12 md:w-1/12  md:pt-20 pl-10">
                    </div>

                    <div className="lg:w-3/12 md:w-6/12  lg:pt-24  justify-center items-center">
                        <img
                            className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white mt-6 md:ml-14"
                            src={require("assets/img/imgs/Shopify.png").default}
                            alt="Best ecommerce website development company Bangalore "
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white "
                            src={require("assets/img/imgs/woocommerce.png").default}
                            alt="Best seo consultant in Bangalore"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white md:ml-14"
                            src={require("assets/img/imgs/Magentoecom.png").default}
                            alt="Digital marketing company in Bangalore"
                        />
                    </div>
                    <div className="lg:w-2/12 md:w-2/12  md:pt-20 pl-10">
                    </div>
                </div>
            </div>


            {/* E-COMMERCE*/}

            <div className="w-full lg:w-12/12 md:w-12/12 pt-12 pb-12 md:hidden block">
                <div className="flex flex-wrap justify-center md:px-32">
                    <div className="lg:w-6/12 md:w-6/12">
                        <div className="flex flex-wrap  ">
                            <div className=" md:py-10 md:mt-10 px-2">
                                <h1 className="texthome py-2 text-2xl pl-2">
                                    Serving Our Customers <br></br>With Custom <span className="font-extrabold text-blue pb-4">E-Commerce</span> Website Designs
                                </h1>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Our dedicated team of professional developers follows the project development
                                    and management process to customize and develop your E-commerce website that adapts
                                    to your business needs.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    eCommerce websites are in trend today due to people’s demands and preferences.
                                    Ecommerce platforms offer products & services to customers in the virtual environment.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Elegant Carve is one of the renowned eCommerce website development companies in Bangalore
                                    that offer the finest strategies for delivering nothing but the best e-commerce solutions
                                    to our esteemed clients.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    The vision of our company is to provide world-class digital services and support for
                                    highlighting the specialties and products to niche audiences from all across the globe.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/12 md:w-1/12">
                    </div>

                    <div className="lg:w-3/12 md:w-3/12 pt-6 px-4 justify-center items-center">
                        <img
                            className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white mt-6 md:ml-14"
                            src={require("assets/img/imgs/Shopify.png").default}
                            alt="Best ecommerce website development company Bangalore "
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white "
                            src={require("assets/img/imgs/woocommerce.png").default}
                            alt="Best seo consultant in Bangalore"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white md:ml-14"
                            src={require("assets/img/imgs/Magentoecom.png").default}
                            alt="Digital marketing company in Bangalore"
                        />
                    </div>
                    <div className="lg:w-2/12 md:w-2/12  md:pt-20 pl-10">
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}