/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";



export default function Webdevelop() {
    return (
        <>
            <IndexNavbar1 fixed />
            <section className="header relative pt-16 items-center flex ">
            </section>
            {/* 1nd Section (WEB DEVELOPMENT)*/}

            <div className="w-full lg:w-12/12 md:w-12/12 lg:pt-12 md:pb-12 md:block hidden">
                <div className="flex flex-wrap justify-center lg:px-32">
                    <div className="lg:w-6/12 md:w-12/12">
                        <div className="flex flex-wrap  ">
                            <div className=" md:py-10 md:mt-10 lg:px-0 md:px-12 lg:pl-10">
                                <h1 className="texthome py-2 text-4xl">
                                    Next-generation <span className="font-extrabold text-blue pb-4">Web Application  </span> to digitally transform your business
                                </h1>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    We have strong expertise in providing intuitive, cost-effective, robust, and secure
                                    custom web application development services.A highly interactive and fully-functional website is needed for
                                    your business to keep it on the right track.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Be it helping sustain the fast-paced competition, increasing brand name,
                                    highlighting products and services among customers, or increasing sales and
                                    revenue, a website serves your business in multiple ways.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    We design custom web applications using latest trends and technologies
                                    in right from scratch, completely customized according to your requirements
                                    and incorporating all the vital aspects of your business needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/12 md:w-1/12  md:pt-20 pl-10">
                    </div>

                    <div className="lg:w-3/12 md:w-6/12  lg:pt-20 pl-10 justify-center items-center">
                        <img
                            className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white mt-6 md:ml-14"
                            src={require("assets/img/imgs/mysqldev.png").default}
                            alt="Web and mobile app development company in Bangalore"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white "
                            src={require("assets/img/imgs/phpdev.png").default}
                            alt="Web Application Development Services"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white md:ml-14"
                            src={require("assets/img/imgs/laraveldev.png").default}
                            alt="Bangalore web development company"
                        />
                    </div>
                    <div className="lg:w-2/12 md:w-2/12  md:pt-20 pl-10">
                    </div>
                </div>
            </div>




            {/*  Mobile View (WEB DEVELOPMENT)*/}

            <div className="w-full lg:w-12/12 md:w-12/12 pt-12 pb-12 md:hidden block">
                <div className="flex flex-wrap justify-center ">
                    <div className="lg:w-6/12 md:w-6/12">
                        <div className="flex flex-wrap  ">
                            <div className=" md:py-10  px-2">
                                <h1 className="texthome py-2 text-2xl pl-2">
                                    Next-generation <span className="font-extrabold text-blue pb-4">Web Application  </span> to digitally transform your business
                                </h1>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    We have strong expertise in providing intuitive, cost-effective, robust, and secure
                                    custom web application development services.A highly interactive and fully-functional website is needed for
                                    your business to keep it on the right track.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Be it helping sustain the fast-paced competition, increasing brand name,
                                    highlighting products and services among customers, or increasing sales and
                                    revenue, a website serves your business in multiple ways.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    We design custom web applications using latest trends and technologies
                                    in right from scratch, completely customized according to your requirements
                                    and incorporating all the vital aspects of your business needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/12 md:w-1/12  md:pt-20 pl-10">
                    </div>

                    <div className="lg:w-3/12 md:w-3/12  pt-6 px-4 justify-center items-center pb-12">
                        <img
                            className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white mt-6 md:ml-14"
                            src={require("assets/img/imgs/mysqldev.png").default}
                            alt="Web and mobile app development company in Bangalore"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white "
                            src={require("assets/img/imgs/phpdev.png").default}
                            alt="Web Application Development Services"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white md:ml-14"
                            src={require("assets/img/imgs/laraveldev.png").default}
                            alt="Bangalore web development company"
                        />
                    </div>
                    <div className="lg:w-2/12 md:w-2/12  md:pt-20 pl-10">
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}