/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";

export default function Works() {
    return (
        <>
            <IndexNavbar1 fixed />
            <section className="header relative pt-16 items-center flex ">
            </section>
            <section className="container mx-auto  md:pt-24 md:block hidden">
                <h1 className="texthome text-4xl text-center font-extrabold  text-blue">
                    Our Works
                </h1>
                <p className="head1 text-lg  text-center md:px-32 text-charcoal400 pt-2">
                    We could help you in different areas like website design,
                    logo design and brochure design as your expectations and its an
                    immune pleasure to make your work unique and creative
                </p>
                <div className="w-full lg:w-12/12 md:w-12/12 md:px-12 md:pt-12">
                    <h1 className="texthome text-2xl  font-extrabold  text-blue pl-2 ">
                        USA <span className="text-red">&</span> CANADA
                    </h1>
                    <div className="flex flex-wrap md:pt-6">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://ibusinessconsulting.ca/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdev.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            iBusiness Consulting
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://www.recrutementplus.ca/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Recrutment Plus
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://techtrinities.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Techtrinities
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://www.gifrinc.org/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Gifrinc
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>



                    <div className="flex flex-wrap md:pt-6">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://therevivehealth.ca/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Revive Health
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://incomeforfamily.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Income For Family
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://vcarephysiorehab.ca/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Vcarephysio Rehab
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>



                <div className="w-full lg:w-12/12 md:w-12/12 md:px-12 md:pt-12">
                    <h1 className="texthome text-2xl  font-extrabold  text-blue pl-2 ">
                        UAE <span className="text-red">&</span> MALASIYA
                    </h1>
                    <div className="flex flex-wrap md:pt-6">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://24by7rescuelink.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdev.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            24By7Rescue<br></br> Link

                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="http://aromainfini.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Aroma <br></br>Infinite
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://rjsqualitycleaningservices.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Rjs Quality <br></br>Cleaning Services
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://www.clouddimensionstechnology.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Cloud Dimension <br></br>Technology
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>



                <div className="w-full lg:w-12/12 md:w-12/12 md:px-12 md:pt-12">
                    <h1 className="texthome text-2xl  font-extrabold  text-blue pl-2 ">
                        NewZealand
                    </h1>
                    <div className="flex flex-wrap md:pt-6">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://arasan.org.nz/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Arasan Group

                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://www.radio.arasan.co.nz/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdev.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Arasan Radio

                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://rightdeals.co.nz/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Right Deals
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://bargainmart.co.nz/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Bargain Mart
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4 md:mt-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            KiwiFixit
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>




                <div className="w-full lg:w-12/12 md:w-12/12 md:px-12 md:pt-12">
                    <h1 className="texthome text-2xl  font-extrabold  text-blue pl-2 ">
                        INDIA
                    </h1>
                    <div className="flex flex-wrap md:pt-6">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://www.clouddimensionstechnology.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            IK Softech

                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://pdpandeyrm.co.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-sm text-black font-bold pl-2">
                                            Pdpandye Rajpati<br></br> Mahavidyalaya
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://millennialz.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Millennialz
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://www.howexim.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Howexim
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>



                    <div className="flex flex-wrap md:pt-6">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://motherlandgroup.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Motherland
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://agrizy.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Agrizy
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://cjinfosolutions.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Cj Info solution
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://palacepainters.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Palace Painters
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>



                    <div className="flex flex-wrap md:pt-6">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://genupinfotech.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Genup Infotech
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://inidhigroup.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            iNidhi Group
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://volksskatt.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Volkskatt
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://isleeplife.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            iSleep Life
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>


                    <div className="flex flex-wrap md:pt-6 md:pb-32">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://bshomecares.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            BS Home Cares
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://protechnologys.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Pro Technologys
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://tanishkaeventsandpartyshop.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Tanishka Cake Shop
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="http://www.mygiftstory.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            My Gift Story
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </section>






            {/* Mobile View */}


            <section className="container mx-auto  pt-12 md:hidden block">
                <h1 className="texthome text-3xl text-center font-extrabold  text-blue">
                    Our Works
                </h1>
                <p className="head1 text-base  text-center px-2 text-charcoal400 pt-2">
                    We could help you in different areas like website design,
                    logo design and brochure design as your expectations and its an
                    immune pleasure to make your work unique and creative
                </p>
                <div className="w-full lg:w-12/12 md:w-12/12 md:px-12 pt-12">
                    <h1 className="texthome text-2xl  font-extrabold  text-blue pl-2 ">
                        USA <span className="text-red">&</span> CANADA
                    </h1>
                    <div className=" flex-wrap md:pt-6">
                        <div className="px-2 py-2">
                            <a href="https://ibusinessconsulting.ca/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdev.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            iBusiness Consulting
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://www.recrutementplus.ca/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Recrutment Plus
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://techtrinities.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Techtrinities
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://www.gifrinc.org/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Gifrinc
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>



                    <div className="flex-wrap md:pt-6 py-2">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://therevivehealth.ca/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Revive Health
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://incomeforfamily.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Income For Family
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://vcarephysiorehab.ca/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Vcarephysio Rehab
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>



                <div className="w-full lg:w-12/12 md:w-12/12 md:px-12 pt-12">
                    <h1 className="texthome text-2xl  font-extrabold  text-blue pl-2 ">
                        UAE <span className="text-red">&</span> MALASIYA
                    </h1>
                    <div className="flex-wrap md:pt-6 py-2">
                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://24by7rescuelink.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdev.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            24By7Rescue Link

                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="http://aromainfini.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Aroma Infinite
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://rjsqualitycleaningservices.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Rjs Quality <br></br>Cleaning Services
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://www.clouddimensionstechnology.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Cloud Dimension Technology
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>



                <div className="w-full lg:w-12/12 md:w-12/12 md:px-12 pt-12">
                    <h1 className="texthome text-2xl  font-extrabold  text-blue pl-2 ">
                        NewZealand
                    </h1>
                    <div className="flex-wrap pt-4">
                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://arasan.org.nz/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Arasan Group

                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://www.radio.arasan.co.nz/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdev.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Arasan Radio

                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://rightdeals.co.nz/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Right Deals
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://bargainmart.co.nz/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Bargain Mart
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4 md:mt-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            KiwiFixit
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>




                <div className="w-full lg:w-12/12 md:w-12/12 md:px-12 pt-12">
                    <h1 className="texthome text-2xl  font-extrabold  text-blue pl-2 ">
                        INDIA
                    </h1>
                    <div className="flex-wrap md:pt-6">
                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://www.clouddimensionstechnology.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            IK Softech

                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://pdpandeyrm.co.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-sm text-black font-bold pl-2">
                                            Pdpandye Rajpati<br></br> Mahavidyalaya
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://millennialz.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Millennialz
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://www.howexim.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Howexim
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>



                    <div className="flex-wrap md:pt-6 py-2">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://motherlandgroup.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Motherland
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://agrizy.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Agrizy
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://cjinfosolutions.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Cj Info solution
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://palacepainters.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Palace Painters
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>



                    <div className="flex-wrap md:pt-6 py-2">
                        <div className="lg:w-3/12 md:w-3/12 px-2">
                            <a href="https://genupinfotech.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Genup Infotech
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://inidhigroup.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            iNidhi Group
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://volksskatt.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Volkskatt
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://isleeplife.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            iSleep Life
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>


                    <div className="flex-wrap md:pt-6 pb-32">
                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://bshomecares.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            BS Home Cares
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://protechnologys.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/webdesn.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Pro Technologys
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="https://tanishkaeventsandpartyshop.com/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            Tanishka Cake Shop
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="lg:w-3/12 md:w-3/12 px-2 py-2">
                            <a href="http://www.mygiftstory.in/" target="_blank">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <div className="flex flex-wrap">
                                        <div className="">
                                            <img
                                                className="w-16 "
                                                src={require("assets/img/imgs/ecom.png").default}
                                                alt="..."
                                            />
                                        </div>
                                        <h1 className="head1 text-lg text-black font-bold pl-2">
                                            My Gift Story
                                            <div className="flex text-black font-normal text-sm pt-2 ">
                                                <p className="pr-4">
                                                    Learn More
                                                </p>
                                                <i class="fa fa-arrow-right pt-1"></i>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    );
}