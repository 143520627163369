/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";


export default function Services() {
    return (
        <>
            <IndexNavbar1 fixed />
            <section className="header relative pt-16 items-center flex ">

            </section>

            {/* OUR SERVICES*/}
            <section className="mx-auto color-bg-subtle md:pb-24 md:block hidden">
                <section className="w-full mx-auto h-600-px md:pb-24">
                    <section className="container mx-auto md:pt-12 relative ">
                        <h1 className="font-extrabold text-blue text-center texthome text-4xl pt-20">
                            Power Our Results
                        </h1>
                        <p className="head1 text-lg  text-center md:px-32 text-charcoal400 pt-4">
                            Based on the analysis of your requirements, we train our focus choosing the right design and other
                            technical <br></br>constructs so that the resulting website solution will resonate with you and your target
                            audience as well.
                        </p>
                    </section>
                    <div className="w-full lg:w-12/12 md:w-12/12 md:px-32 md:pt-12">
                        <div className="flex flex-wrap items-center justify-center md:px-32">
                            <div className="lg:w-4/12 md:w-4/12 md:px-4">
                                <a href="/webdesign">
                                    <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                        <img
                                            className=""
                                            src={require("assets/img/image1.png").default}
                                            alt="..."
                                        />
                                    </div>
                                </a>
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 md:px-4">
                                <a href="/webdevelop">
                                    <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                        <img
                                            className=""
                                            src={require("assets/img/image6.png").default}
                                            alt="..."
                                        />
                                    </div>
                                </a>
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 md:px-4">
                                <a href="/marketing">
                                    <div className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                        <img
                                            className=""
                                            src={require("assets/img/image3.png").default}
                                            alt="..."
                                        />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:w-12/12 md:w-12/12 md:px-32 md:pt-6">
                        <div className="flex flex-wrap items-center justify-center md:px-32">
                            <div className="lg:w-4/12 md:w-4/12 md:px-4">
                                <a href="/ecommerce">
                                    <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                        <img
                                            className=""
                                            src={require("assets/img/image4.png").default}
                                            alt="..."
                                        />
                                    </div>
                                </a>
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 md:px-4">
                            <a href="/appdevelop">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <img
                                        className=""
                                        src={require("assets/img/image5.png").default}
                                        alt="..."
                                    />
                                </div>
                                </a>
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 md:px-4">
                                <div className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <img
                                        className=""
                                        src={require("assets/img/image2.png").default}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>


            {/* OUR SERVICES MOBILE VIEW*/}
            <section className="mx-auto color-bg-subtle pb-12 md:hidden block">
                <section className="w-full mx-auto  pb-12">
                    <section className="container mx-auto relative ">
                        <h1 className="font-extrabold text-blue text-center texthome text-3xl pt-20">
                            Power Our Results
                        </h1>
                        <p className="head1 text-base  text-center px-2 text-charcoal400 pt-4">
                            Based on the analysis of your requirements, we train our focus choosing the right design and other
                            technical constructs so that the resulting website solution will resonate with you and your target
                            audience as well.
                        </p>
                    </section>
                    <div className="w-full lg:w-12/12 md:w-12/12 md:px-32 pt-6">
                        <div className="flex flex-wrap items-center justify-center md:px-32">
                            <div className="lg:w-4/12 md:w-4/12 px-4 py-2">
                                <a href="/webdesign">
                                    <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                        <img
                                            className=""
                                            src={require("assets/img/image1.png").default}
                                            alt="..."
                                        />
                                    </div>
                                </a>
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 px-4 py-2 ">
                                <a href="/webdevelop">
                                    <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                        <img
                                            className=""
                                            src={require("assets/img/image6.png").default}
                                            alt="..."
                                        />
                                    </div>
                                </a>
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 px-4 py-2">
                                <a href="/marketing">
                                    <div className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                        <img
                                            className=""
                                            src={require("assets/img/image3.png").default}
                                            alt="..."
                                        />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:w-12/12 md:w-12/12 md:px-32 md:pt-6">
                        <div className="flex flex-wrap items-center justify-center md:px-32">
                            <div className="lg:w-4/12 md:w-4/12 px-4 py-2">
                                <a href="/ecommerce">
                                    <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                        <img
                                            className=""
                                            src={require("assets/img/image4.png").default}
                                            alt="..."
                                        />
                                    </div>
                                </a>
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 px-4 py-2">
                            <a href="/appdevelop">
                                <div className=" color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <img
                                        className=""
                                        src={require("assets/img/image5.png").default}
                                        alt="..."
                                    />
                                </div>
                                </a>
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 px-4 py-2">
                                <div className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white py-4">
                                    <img
                                        className=""
                                        src={require("assets/img/image2.png").default}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    );
}