/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";
import bgelegantcarve1 from "assets/img/bgelegantcarve1.png"


export default function Career() {
    return (
        <>
            <IndexNavbar1 fixed />
            <section className=" container mx-auto header   relative justify-center items-center  let">
            </section>
            
            <section className=" mx-auto" style={{ backgroundImage: `url(${bgelegantcarve1})` }}>
                <div className="container mx-auto  pt-32 ">
                <h1 className="font-bold text-black text-5xl text-center head1 md:pt-24">
                    Do the best work of
                
                    your life.
                </h1>
                <h1 className="font-bold text-black text-4xl text-center head1 pt-2 pb-2">
                    Design your career with us
                </h1>
                <div className=" pt-2">
                    <p className="head1 text-base text-center pt-2 md:px-32">
                        “Grow as we grow” Are you in search of doing exciting work in an environment you’re going to love?
                        Are you interested to thrive on excellence? Are you a smart worker with passion to work? Are you
                        eager and enjoy solving challenging problems? Then we are the right employer for you. we strive to
                        build empire of Elegant Carve  that shape employee centric approach and enrich the experiences.
                    </p>
                </div>
                <div className="mt-12 center">
                    <a
                        href="https://www.creative-tim.com/learning-lab/tailwind/react/overview/notus?ref=nr-index"
                        target="_blank"
                        className="get-started text-white font-bold px-4 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-buttongreen active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    >
                        Explore Our Services
                    </a>
                </div>
                <div className="center md:pt-12 md:pl-32">
                    <img
                        className="mt-12  pl-10"
                        src={require("assets/img/careersample.png").default}
                        alt="..."
                    />
                </div>
                </div>
            </section>


            <section className="bg-blueGray-100">
                <div className="container mx-auto pt-12 md:pb-32 ">
                    <div className="flex flex-wrap">
                        <div className="lg:w-3/12 md:w-3/12 ">

                        </div>
                        <div className="lg:w-6/12 md:w-6/12 ">

                            <h1 className="font-bold texts head1 text-5xl pt-6 pb-2 text-center">
                                Submit Your Resume Here..
                            </h1>
                            <p className="text-base pt-2 text-center head1">
                                You are welcome to join an enthusiastic team of Elegantcarve individuals in the world of technology and
                                innovation. Join our quest in keeping up with the latest technology, innovation, and sustained professional.
                            </p>
                            <form className="pt-12 px-6">
                                <div className="relative w-full py-6s ">
                                    <label className="py-2 font-bold text-sm">
                                        Name
                                    </label>
                                    <input type="text"
                                        className="border-1 mt-2 px-3 py-3 bg-white text-blueGray-600 placeholder-blueGray-300 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                        placeholder="Name" name="name" maxlength="25"
                                    />
                                </div>
                                <div className="relative w-full py-6">
                                    <label className="py-2 font-bold text-sm">
                                        Email
                                    </label>
                                    <input type="text"
                                        className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                        placeholder="Email" name="email"
                                    />
                                    <div className="relative w-full py-6">
                                        <label className="py-2 font-bold text-sm">
                                            Contact Number
                                        </label>
                                        <input type="text"
                                            className="border-1 mt-2 px-3 py-3 bg-white text-blueGray-600 placeholder-blueGray-300 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                            placeholder="contact number" name="tel" maxlength="25"
                                        />
                                    </div>
                                    <div className="relative w-full ">
                                        <label className="py-2 font-bold text-sm ">
                                            Message
                                        </label><br></br>
                                        <textarea
                                            className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                            placeholder="Message" name="message"
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                >
                                    <input type="file" name="attachment" accept="image/png, image/jpeg" required />
                                </div>
                                <div className="text-center pb-6">
                                    <button
                                        className=" bg-buttongreen text-white  text-md font-extrabold uppercase px-12 mt-6 py-3 rounded px-6 hover:shadow-lg"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="lg:w-3/12 md:w-3/12 ">
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}