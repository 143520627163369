import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/style.css";
import "assets/styles/style.scss";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";
import About from "components/About.js";
import Services from "components/Services.js";
import Webdesign from "components/service/Webdesign.js";
import Webdevelop from "components/service/Webdevelop.js";
import Marketing from "components/service/Marketing.js";
import Appdevelop from "components/service/Appdevelop.js";
import Ecommerce from "components/service/Ecommerce.js";
import Contact from "components/Contact.js";
import Technologies from "components/Technologies.js";
import Career from "components/Career.js";
import Testimonial from "components/Testimonial.js";
import Works from "components/Works.js";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/" exact component={Index} />
      <Route path="/about" exact component={About}/>
      <Route path="/testimonial" exact component = {Testimonial}/>
      <Route path="/works" exact component = {Works}/>
      <Route path="/services" exact component ={Services}/>
      <Route path="/webdesign" exact component={Webdesign}/>
      <Route path="/webdevelop" exact component={Webdevelop}/>
      <Route path="/marketing" exact component={Marketing}/>
      <Route path="/appdevelop" exact component={Appdevelop}/>
      <Route path="/ecommerce" exact component={Ecommerce}/>
      <Route path="/contact" exact component={Contact}/>
      <Route path="/tech" exact component={Technologies}/>
      <Route path="/career" exact component ={Career}/>
      
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
