/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";



export default function Marketing() {
    return (
        <>
            <IndexNavbar1 fixed />
            <section className="header relative pt-16 items-center flex ">
            </section>
            {/* MARKETING)*/}

            <div className="w-full lg:w-12/12 md:w-12/12 lg:pt-12 md:pb-12 md:block hidden">
                <div className="flex flex-wrap justify-center lg:px-32">
                    <div className="lg:w-6/12 md:w-12/12">
                        <div className="flex flex-wrap  ">
                            <div className=" md:py-10 md:mt-10 lg:px-0 md:px-12 lg:pl-10">
                                <h1 className="texthome py-2 text-4xl">
                                    Our Expert  <span className="font-extrabold text-blue pb-4">Digital Marketing </span>
                                    <br></br> Services
                                </h1>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Businesses today can enormously thrive by leveraging what social media has to offer.
                                    The great aspect of social media is that a lot of the potential customers are there.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Thereby, it becomes one of the best places to reach out to them and market your products
                                    or services. Elegant Carve is an industry-leading Social Media Marketing Company in Bangalore.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    We creating brand awareness, having quality leads, or enhancing sales,
                                    we at Elegant Carve as a flourishing Social Media Marketing in Bangalore
                                    are able to successfully assist our clients in boosting their overall presence.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    We deliver creative and unique work that encourages creating brand awareness digitally
                                    and directly influences improvement in revenue.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/12 md:w-1/12  md:pt-32 pl-10">
                    </div>

                    <div className="lg:w-3/12 md:w-6/12  lg:pt-24 pl-10 justify-center items-center">
                        <img
                            className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white mt-6 md:ml-14"
                            src={require("assets/img/imgs/PPCMarketing.png").default}
                            alt="top online marketing companies in Bangalore"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white "
                            src={require("assets/img/imgs/SEOMarketing.png").default}
                            alt="Top web design and digital marketing company in Bangalore"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white md:ml-14"
                            src={require("assets/img/imgs/SMMMarketing.png").default}
                            alt="Top SEO providers in Bangalore"
                        />
                    </div>
                    <div className="lg:w-2/12 md:w-2/12  md:pt-20 pl-10">
                    </div>
                </div>
            </div>


            {/* MOBILE VIEW MARKETING)*/}

            <div className="w-full lg:w-12/12 md:w-12/12 pt-12 pb-12 md:hidden block">
                <div className="flex flex-wrap justify-center md:px-32">
                    <div className="lg:w-6/12 md:w-6/12">
                        <div className="flex flex-wrap  ">
                            <div className=" md:py-10 md:mt-10  px-2">
                                <h1 className="texthome py-2 text-2xl pl-2">
                                    Our Expert  <span className="font-extrabold text-blue pb-4">Digital Marketing </span>
                                    <br></br> Services
                                </h1>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Businesses today can enormously thrive by leveraging what social media has to offer.
                                    The great aspect of social media is that a lot of the potential customers are there.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    Thereby, it becomes one of the best places to reach out to them and market your products
                                    or services. Elegant Carve is an industry-leading Social Media Marketing Company in Bangalore.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    We creating brand awareness, having quality leads, or enhancing sales,
                                    we at Elegant Carve as a flourishing Social Media Marketing in Bangalore
                                    are able to successfully assist our clients in boosting their overall presence.
                                </p>
                                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                                    We deliver creative and unique work that encourages creating brand awareness digitally
                                    and directly influences improvement in revenue.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/12 md:w-1/12 0">
                    </div>

                    <div className="lg:w-3/12 md:w-3/12  pt-6 px-4 justify-center items-center">
                        <img
                            className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white mt-6 md:ml-14"
                            src={require("assets/img/imgs/PPCMarketing.png").default}
                            alt="top online marketing companies in Bangalore"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white "
                            src={require("assets/img/imgs/SEOMarketing.png").default}
                            alt="Top web design and digital marketing company in Bangalore"
                        />
                        <img
                            className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white md:ml-14"
                            src={require("assets/img/imgs/SMMMarketing.png").default}
                            alt="Top SEO providers in Bangalore"
                        />
                    </div>
                    <div className="lg:w-2/12 md:w-2/12  md:pt-20 pl-10">
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}