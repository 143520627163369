/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo} from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import bgelegantcarve from "assets/img/bgelegantcarve.png"

import emailjs from "emailjs-com";





export default function Contact() {

    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [checkboxValues, setCheckboxValues] = useState([]);

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailregexError, setEmailRegexError] = useState(false);
    const [telError, setTelError] = useState(false);
    const [telregexError, setTelRegexError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        setCheckboxValues((prev) => 
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(firstName.length === 0);
        setEmailError(email.length === 0);
        setTelError(tel.length === 0);
        setMessageError(message.length === 0);
        setCheckboxError(checkboxValues.length === 0);

        if (email.length > 0 && !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        } else {
            setEmailRegexError(false);
        }

        if (tel.length > 0 && !(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) {
            setTelRegexError(true);
        } else {
            setTelRegexError(false);
        }

        if (firstName && email && tel && message && checkboxValues.length > 0 && !emailregexError && !telregexError) {
            console.log("First Name: ", firstName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);
            console.log("Services: ", checkboxValues.join(", "));

            emailjs.sendForm('service_pkgyfs7', 'template_bnog1sk', e.target, '076KSZ_e1qDX1KE1V')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            alert("Form Submitted Successfully");

            setFirstName('');
            setEmail('');
            setTel('');
            setMessage('');
            setCheckboxValues([]);

            // Reset the form
            e.target.reset();
        }
    };


    return (
        <>
            <IndexNavbar fixed />
            <section className=" container mx-auto header relative justify-center items-center md:pt-36 pt-12 let">
            </section>
            <a href="https://wa.me/+919036323422" class="float" target="_blank">
<i class="fab fa-whatsapp my-float"></i>
</a>

            {/* <section className="container mx-auto pb-16 md:block hidden">
                <div className="w-full lg:w-12/12 md:w-12/12 pt-12 ">
                    <div className="flex flex-wrap rounded ">
                        <div className="lg:w-5/12 md:w-5/12 seasalt pt-4 pb-16">
                            <div className="center">
                                <img
                                    className="w-6/12"
                                    src={require("assets/img/imgs/contactimage.png").default}
                                    alt="..."
                                />
                            </div>
                            <h1 className="texthome font-extrabold text-2xl text-left  pl-10 text-black">
                                GET IN
                                <span className="text-green1 px-2">
                                    TOUCH
                                </span>
                            </h1>
                            <p className="head1 text-lg text-left  pl-10 pt-2">
                                Share your project requirement with us or any question you <br></br>have pertaining to our services,
                                we will get back to you<br></br> as soon as possible.
                            </p>
                            <div className="lg:w-8/12 md:w-8/12 pt-12 px-2 pl-6 ">
                                <div className="flex flex-wrap">
                                    <div className="lg:w-3/12 md:w-3/12 center pt-2">
                                        <img
                                            className="w-10"
                                            src={require("assets/img/phone.png").default}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="lg:w-8/12 md:w-8/12 text-bold text-black ">
                                        <h1 className="font-bold head1 text-black text-md">
                                            Call Us
                                        </h1>
                                        <h1 className="head1 text-black text-sm">
                                            info@elegantcarve.com
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-8/12 md:w-8/12 pt-8 px-2 pl-6">
                                <div className="flex flex-wrap">
                                    <div className="lg:w-3/12 md:w-3/12 center pt-2">
                                        <img
                                            className="w-10"
                                            src={require("assets/img/mail.png").default}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="lg:w-8/12 md:w-8/12 text-bold text-black ">
                                        <h1 className="font-bold head1 text-black text-md">
                                            Mail Us
                                        </h1>
                                        <h1 className="head1 text-black text-sm">
                                            info@elegantcarve.com
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-8/12 md:w-8/12 pt-8 px-2 pl-6">
                                <div className="flex flex-wrap">
                                    <div className="lg:w-3/12 md:w-3/12 center pt-2">
                                        <img
                                            className="w-10"
                                            src={require("assets/img/location.png").default}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="lg:w-8/12 md:w-8/12 text-bold text-black ">
                                        <h1 className="font-bold head1 text-black text-md">
                                            Addresss
                                        </h1>
                                        <h1 className="font-bold head1 text-blue text-md pt-1">
                                            INDIA
                                        </h1>
                                        <h1 className="head1 text-black text-sm">
                                            Novel Tech Park, #46/4, Kudlu Gate, Hosur Rd, HSR Extension, Bengaluru, Karnataka 560068
                                        </h1>
                                        <h1 className="font-bold head1 text-blue text-md pt-4">
                                            CANADA
                                        </h1>
                                        <h1 className="head1 text-black font-bold text-sm">
                                            ibusinessconsulting
                                        </h1>
                                        <h1 className="head1 text-black text-sm">
                                            4488 Rue Cartier
                                            Montreal QC H2H IW6.
                                            +1(438)9881743
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-6/12 md:w-6/12 pt-12 pl-10 pb-16">
                            <h1 className="font-bold text-blue text-3xl texthome">
                                Contact Us
                            </h1>
                            <p className="text-lg head1 pt-2">
                                If you are really interested to get creative and feasible work,
                                do interact with us just feel an awesome experience with us and we love to help you
                                in all the above aspects.
                            </p>
                            <form action="https://formsubmit.co/praba.elegantcarve@gmail.com"
                                enctype="multipart/form-data" method="POST">
                                <div className="relative w-full">
                                    <div className="relative w-full py-2 ">
                                        <label className="py-2 font-bold text-sm">
                                            Name
                                        </label>
                                        <div class="mb-3">
                                            <input name="name" type="text" className="border-1 mt-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                placeholder="Name" maxlength="25" required />
                                        </div>
                                    </div>

                                    <div className="relative w-full py-2">
                                        <label className="py-2 font-bold text-sm">
                                            Email
                                        </label>
                                        <div class="mb-3">
                                            <input name="email" type="email" className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                placeholder="Email" required />
                                        </div>

                                    </div>
                                    <div className="relative w-full py-6">
                                        <label className="py-2 font-bold text-sm">
                                            Contact Number
                                        </label>
                                        <div class="mb-3">
                                            <input name="tel" type="text" className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                placeholder="Phone Number" required />
                                        </div>
                                    </div>

                                    <div className="py-2">
                                        <label className="py-2 font-bold text-sm ">
                                            Which Service You are interseted in?
                                        </label><br></br>
                                        <div className="py-4">
                                            <div className="w-full lg:w-12/12 md:w-12/12 py-2">
                                                <div className="flex flex-wrap">
                                                    <div className="lg:w-6/12 md:w-6/12">
                                                        <input type="checkbox" id="vehicle1" name="values" value="I like to choose Web Design" />
                                                        <label for="vehicle1" className="px-2 text-sm">Web Design</label><br></br>
                                                    </div>
                                                    <div className="lg:w-6/12 md:w-6/12">
                                                        <input type="checkbox" id="vehicle2" name="values" value="I like to choose Web Development" />
                                                        <label for="vehicle2" className="px-2 text-sm">Web Development</label><br></br>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-12/12 md:w-12/12">
                                                <div className="flex flex-wrap">
                                                    <div className="lg:w-6/12 md:w-6/12">
                                                        <input type="checkbox" id="vehicle3" name="values" value="I like to choose App Development" />
                                                        <label for="vehicle3" className="px-2 text-sm">App Development</label><br></br>
                                                        <div className="py-2">
                                                            <input type="checkbox" id="vehicle3" name="values" value="I like to choose E-Commerce" />
                                                            <label for="vehicle3" className="px-2 text-sm">E-Commerce</label><br></br>
                                                        </div>

                                                    </div>
                                                    <div className="lg:w-6/12 md:w-6/12">
                                                        <input type="checkbox" id="vehicle3" name="values" value="I like to choose Marketing" />
                                                        <label for="vehicle3" className="px-2 text-sm">Marketing</label><br></br>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="relative w-full ">
                                        <label className="py-2 font-bold text-sm ">
                                            Message
                                        </label><br></br>
                                        <div class="mb-3">
                                            <textarea name="message" type="text" className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                placeholder="Message" required></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-right mt-6">
                                    <button
                                        className="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-buttongreen active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>*/}




            <section className="container mx-auto md:pt-24 md:block hidden">
                <div className="w-full lg:w-12/12 md:w-12/12  mt-6">
                    <div className="lg:px-32 md:pb-12">
                        <h1 className="font-bold text-blue text-3xl texthome text-center">
                            Contact Us
                        </h1>
                        <p className="text-lg head1 pt-2 text-center">
                            If you are really interested to get creative and feasible work,
                            do interact with us just feel an awesome experience with us and we love to help you
                            in all the above aspects.
                        </p>
                    </div>
                    <div className="row flex">
                        <div className="md:w-0/12 lg:w-2/12  px-4">

                        </div>
                        <div className="md:w-5/12 lg:w-4/12  px-4">
                            <div className="pricing_inner pricing_blue_bg py-24">
                                <div className="md:px-6">
                                    <div className="flex md:-mt-16 flex-wrap">
                                        <img
                                            className="w-8"
                                            src={require("assets/img/location.png").default}
                                            alt="..."
                                        />
                                        <h1 className="head1 text-xl font-bold text-blue px-2 ">
                                            INDIA
                                        </h1>
                                    </div>
                                    <h1 className="head1 text-black text-sm px-10">
                                        Novel Tech Park, #46/4, Kudlu Gate, Hosur Rd, HSR Extension, Bengaluru, Karnataka 560068
                                    </h1>
                                    <h1 className="head1 text-black text-sm px-10 pt-2">
                                        +91 9036323422.
                                    </h1>
                                    <h1 className="head1 text-black text-sm px-10 pt-2">
                                        info@elegantcarve.com
                                    </h1>

                                </div>
                            </div>
                        </div>
                        <div className="md:w-5/12 lg:w-4/12  px-4">
                            <div className="pricing_inner pricing_red_bg py-24">
                                <div className="md:px-6">
                                    <div className="flex md:-mt-16 flex-wrap">
                                        <img
                                            className="w-8"
                                            src={require("assets/img/location.png").default}
                                            alt="..."
                                        />
                                        <h1 className="head1 text-xl font-bold text-red px-2 ">
                                            CANADA
                                        </h1>
                                    </div>
                                    <h1 className="head1 text-black font-bold text-sm px-10">
                                        ibusinessconsulting
                                    </h1>
                                    <h1 className="head1 text-black text-sm px-10 pt-1">
                                        4488 Rue Cartier
                                        Montreal <br></br>QC H2H IW6.
                                    </h1>
                                    <h1 className="head1 text-black text-sm px-10 pt-2 pb-4">
                                        +1(438)9881743
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-0/12 lg:w-2/12  px-4">
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mx-auto md:pb-32 md:block hidden">
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-3/12 md:w-0/12">
                        </div>
                        <div className="lg:w-6/12 md:w-12/12 lg:px-0 md:px-24">
                        <form onSubmit={handleSubmit}>
                                        <div className="relative w-full">
                                            <div className="relative w-full py-2 ">
                                                <label className="py-2 font-bold text-sm">
                                                    Name
                                                </label>
                                                <div className="mb-3">
                                                    <input className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-blueGray-300 border"
                                                        placeholder="First Name" name="name" maxLength="25" onChange={e => setFirstName(e.target.value)} />
                                                    {nameError && firstName.length === 0 ?
                                                        <label className="error">Enter Your First Name</label> : ""}
                                                </div>
                                            </div>

                                            <div className="relative w-full ">
                                                <label className="py-2 font-bold text-sm">
                                                    Email
                                                </label>
                                                <div className="mb-3">
                                                    <input className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-blueGray-300 border"
                                                        placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                                    {emailError && email.length === 0 ?
                                                        <label className="error">Enter Your Email</label> : ""}
                                                    {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                                        <label className="error">Please Enter Valid Email</label> : ""}
                                                </div>
                                            </div>
                                            <div className="relative w-full py-2">
                                                <label className="py-2 font-bold text-sm">
                                                    Contact Number
                                                </label>
                                                <div className="mb-3">
                                                    <input className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-blueGray-300 border"
                                                        placeholder="Phone Number" name="tel" maxLength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)} />
                                                    {telError && tel.length === 0 ?
                                                        <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                                    {telregexError && (!(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) ?
                                                        <label className="error pt-2">Please Enter Valid Number</label> : ""}
                                                </div>
                                            </div>

                                            <div className="py-2">
                                                <label className="py-2 font-bold text-sm ">
                                                    Which Service You are interested in?
                                                </label><br />
                                                <div className="py-4">
                                                    <div className="w-full lg:w-12/12 md:w-12/12 py-2">
                                                        <div className="flex flex-wrap">
                                                            <div className="lg:w-6/12 md:w-6/12">
                                                                <input type="checkbox" id="web_design" name="values" value="Web Design" onChange={handleCheckboxChange} />
                                                                <label htmlFor="web_design" className="px-2 text-sm">Web Design</label><br />
                                                            </div>
                                                            <div className="lg:w-6/12 md:w-6/12">
                                                                <input type="checkbox" id="web_development" name="values" value="Web Development" onChange={handleCheckboxChange} />
                                                                <label htmlFor="web_development" className="px-2 text-sm">Web Development</label><br />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="w-full lg:w-12/12 md:w-12/12">
                                                        <div className="flex flex-wrap">
                                                            <div className="lg:w-6/12 md:w-6/12">
                                                                <input type="checkbox" id="app_development" name="values" value="App Development" onChange={handleCheckboxChange} />
                                                                <label htmlFor="app_development" className="px-2 text-sm">App Development</label><br />
                                                                <div className="py-2">
                                                                    <input type="checkbox" id="e_commerce" name="values" value="E-Commerce" onChange={handleCheckboxChange} />
                                                                    <label htmlFor="e_commerce" className="px-2 text-sm">E-Commerce</label><br />
                                                                </div>
                                                            </div>
                                                            <div className="lg:w-6/12 md:w-6/12">
                                                                <input type="checkbox" id="marketing" name="values" value="Marketing" onChange={handleCheckboxChange} />
                                                                <label htmlFor="marketing" className="px-2 text-sm">Marketing</label><br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {checkboxError && checkboxValues.length === 0 ?
                                                    <label className="error">Please select at least one service</label> : ""}
                                            </div>

                                            <div className="relative w-full ">
                                                <label className="py-2 font-bold text-sm ">
                                                    Message
                                                </label><br />
                                                <div className="mb-3">
                                                    <textarea className="border-blueGray-300 border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                        placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                                    {messageError && message.length === 0 ?
                                                        <label className="error">Please Enter Your Message</label> : ""}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-right mt-6">
                                            <button
                                                className="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-buttongreen active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                                type="submit"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                        </div>
                        <div className="lg:w-3/12 md:w-3/12">

                        </div>
                    </div>
                </div>
            </section>

            <section className="md:block hidden">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d741.8363419965091!2d77.64116483611478!3d12.891492062967423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6dd3d7ea9d39%3A0x16179999afcd9256!2sElegant%20Carve%20Infotech%20Private%20Limited!5e0!3m2!1sen!2sin!4v1673676312381!5m2!1sen!2sin" allowfullscreen="" width="1510" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>

            {/* MOBILE VIEW */}
            <section className="container mx-auto  md:hidden block">
                <div className="w-full lg:w-12/12 md:w-12/12 ">
                    <div className="flex flex-wrap rounded ">
                        <div className="lg:w-5/12 md:w-5/12 seasalt pt-12 pb-16">
                            <div className="center">
                                <img
                                    className="w-6/12"
                                    src={require("assets/img/imgs/contactimage.png").default}
                                    alt="..."
                                />
                            </div>
                            <h1 className="texthome font-extrabold text-2xl text-left  px-4 text-black">
                                GET IN
                                <span className="text-green1 px-2">
                                    TOUCH
                                </span>
                            </h1>
                            <p className="head1 text-lg text-left  px-4 pt-2">
                                Share your project requirement with us or any question you have pertaining to our services,
                                we will get back to you as soon as possible.
                            </p>
                            <div className="lg:w-8/12 md:w-8/12 pt-12 px-4 pl-6 ">
                                <div className="flex flex-wrap">
                                    <div className="lg:w-3/12 md:w-3/12 center pt-2">
                                        <img
                                            className="w-10"
                                            src={require("assets/img/phone.png").default}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="lg:w-8/12 md:w-8/12 text-bold text-black pl-2">
                                        <h1 className="font-bold head1 text-black text-md">
                                            Call Us
                                        </h1>
                                        <h1 className="head1 text-black text-sm">
                                            +91  9036323422
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-8/12 md:w-8/12 pt-8 px-4 pl-6">
                                <div className="flex flex-wrap">
                                    <div className="lg:w-3/12 md:w-3/12 center ">
                                        <img
                                            className="w-10"
                                            src={require("assets/img/mail.png").default}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="lg:w-8/12 md:w-8/12 text-bold text-black pl-2">
                                        <h1 className="font-bold head1 text-black text-md">
                                            Mail Us
                                        </h1>
                                        <h1 className="head1 text-black text-sm">
                                            info@elegantcarve.com
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-8/12 md:w-8/12 pt-8 px-4">
                                <div className="flex flex-wrap">
                                    <div className="lg:w-3/12 md:w-3/12 center pt-2">
                                        <img
                                            className="w-10"
                                            src={require("assets/img/location.png").default}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="lg:w-8/12 md:w-8/12 text-bold text-black ">
                                        <h1 className="font-bold head1 text-black text-md">
                                            Addresss
                                        </h1>
                                        <h1 className="font-bold head1 text-blue text-md pt-1">
                                            INDIA
                                        </h1>
                                        <h1 className="head1 text-black text-sm">
                                            Novel Tech Park, #46/4, Kudlu Gate, Hosur Rd, HSR Extension, Bengaluru, Karnataka 560068.
                                        </h1>
                                        <h1 className="font-bold head1 text-blue text-md pt-4">
                                            CANADA
                                        </h1>
                                        <h1 className="head1 text-black font-bold text-sm">
                                            ibusinessconsulting
                                        </h1>
                                        <h1 className="head1 text-black text-sm">
                                            4488 Rue Cartier
                                            Montreal QC H2H IW6.
                                            +1(438)9881743
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-6/12 md:w-6/12 pt-12 px-4 ">
                            <h1 className="font-bold text-blue text-3xl texthome">
                                Contact Us
                            </h1>
                            <p className="text-lg head1 pt-2">
                                If you are really interested to get creative and feasible work,
                                do interact with us just feel an awesome experience with us and we love to help you
                                in all the above aspects.
                            </p>
                            <form onSubmit={handleSubmit}>
                                        <div className="relative w-full">
                                            <div className="relative w-full py-2 ">
                                                <label className="py-2 font-bold text-sm">
                                                    Name
                                                </label>
                                                <div className="mb-3">
                                                    <input className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-blueGray-300 border"
                                                        placeholder="First Name" name="name" maxLength="25" onChange={e => setFirstName(e.target.value)} />
                                                    {nameError && firstName.length === 0 ?
                                                        <label className="error">Enter Your First Name</label> : ""}
                                                </div>
                                            </div>

                                            <div className="relative w-full ">
                                                <label className="py-2 font-bold text-sm">
                                                    Email
                                                </label>
                                                <div className="mb-3">
                                                    <input className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-blueGray-300 border"
                                                        placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                                    {emailError && email.length === 0 ?
                                                        <label className="error">Enter Your Email</label> : ""}
                                                    {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                                        <label className="error">Please Enter Valid Email</label> : ""}
                                                </div>
                                            </div>
                                            <div className="relative w-full py-2">
                                                <label className="py-2 font-bold text-sm">
                                                    Contact Number
                                                </label>
                                                <div className="mb-3">
                                                    <input className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-blueGray-300 border"
                                                        placeholder="Phone Number" name="tel" maxLength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)} />
                                                    {telError && tel.length === 0 ?
                                                        <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                                    {telregexError && (!(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) ?
                                                        <label className="error pt-2">Please Enter Valid Number</label> : ""}
                                                </div>
                                            </div>

                                            <div className="py-2">
                                                <label className="py-2 font-bold text-sm ">
                                                    Which Service You are interested in?
                                                </label><br />
                                                <div className="py-4">
                                                    <div className="w-full lg:w-12/12 md:w-12/12 py-2">
                                                        <div className="flex flex-wrap">
                                                            <div className="lg:w-6/12 md:w-6/12">
                                                                <input type="checkbox" id="web_design" name="values" value="Web Design" onChange={handleCheckboxChange} />
                                                                <label htmlFor="web_design" className="px-2 text-sm">Web Design</label><br />
                                                            </div>
                                                            <div className="lg:w-6/12 md:w-6/12">
                                                                <input type="checkbox" id="web_development" name="values" value="Web Development" onChange={handleCheckboxChange} />
                                                                <label htmlFor="web_development" className="px-2 text-sm">Web Development</label><br />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="w-full lg:w-12/12 md:w-12/12">
                                                        <div className="flex flex-wrap">
                                                            <div className="lg:w-6/12 md:w-6/12">
                                                                <input type="checkbox" id="app_development" name="values" value="App Development" onChange={handleCheckboxChange} />
                                                                <label htmlFor="app_development" className="px-2 text-sm">App Development</label><br />
                                                                <div className="py-2">
                                                                    <input type="checkbox" id="e_commerce" name="values" value="E-Commerce" onChange={handleCheckboxChange} />
                                                                    <label htmlFor="e_commerce" className="px-2 text-sm">E-Commerce</label><br />
                                                                </div>
                                                            </div>
                                                            <div className="lg:w-6/12 md:w-6/12">
                                                                <input type="checkbox" id="marketing" name="values" value="Marketing" onChange={handleCheckboxChange} />
                                                                <label htmlFor="marketing" className="px-2 text-sm">Marketing</label><br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {checkboxError && checkboxValues.length === 0 ?
                                                    <label className="error">Please select at least one service</label> : ""}
                                            </div>

                                            <div className="relative w-full ">
                                                <label className="py-2 font-bold text-sm ">
                                                    Message
                                                </label><br />
                                                <div className="mb-3">
                                                    <textarea className="border-blueGray-300 border mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 contactsample"
                                                        placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                                    {messageError && message.length === 0 ?
                                                        <label className="error">Please Enter Your Message</label> : ""}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-right mt-6">
                                            <button
                                                className="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-buttongreen active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                                type="submit"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}