/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";

export default function Testimonial() {
    return (
        <>
            <IndexNavbar1 fixed />
            <section className=" container mx-auto header relative justify-center items-center pt-32 let">
            </section>

            {/* Testimonials */}
            <section className="container mx-auto md:block hidden">
                <h1 className="font-bold texthome text-blue text-4xl text-center md:mt-16 px-12 ">
                    Our Happy Customers
                    <p className="font-normal head1 text-lg text-black pt-2 ">
                        Here is an appreciation to our work from so many happy customers, we really motivated by those words
                        from our satisfied customers.
                    </p>
                </h1>
                <div className="w-full lg:w-12/12 md:w-12/12 md:mt-10 lg:px-12">
                    <div className="flex flex-wrap center">
                        <div className="lg:w-4/12 md:w-4/12 md:px-2 ">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test1.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        My new site designed by Elegant Carve Infotech. They've done a great job of creating
                                        website what I expected. Easy to work with and very responsive. They met all of my
                                        expectations and make us happy, I really existed. Thank yo so much!.

                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Deepthi Srinivas
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        CEO, IK Softec
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-4/12 md:w-4/12 md:px-2 ">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test3.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        The Elegant Carve did a fantastic work creating a website for DSPRO
                                        Films &amp; Sounds. They were friendly and nothing was too much trouble.
                                        A Creative design and on time delivery, we would highly recommend and we
                                        will continue to work with this team.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Dhinakar
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        Actor & Producer, DSPRO
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-4/12 md:w-4/12 md:px-2 ">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test2.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        They Never stop unless the client is satisfied, It’s a very good Web design and developing
                                        company. project submitted in a particular time and date. The Elegant Carve team guided
                                        us with current trends and helped us with smart content.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Srinivasa Rao Muddana
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        CEO, RJS Quality Cleaning Services
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >


                <div className="w-full lg:w-12/12 md:w-12/12 md:mt-4 lg:px-12 md:pb-32">
                    <div className="flex flex-wrap center">
                        <div className="lg:w-4/12 md:w-4/12 md:px-2 ">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test4.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        We are really impressed with the level of development we
                                        received from Elegant Carve Website Design. We are so happy with our
                                        website design and ongoing online marketing results.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Suresh Kumar
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        Founder, Purumara Group
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-4/12 md:w-4/12 md:px-2 ">
                            <div className="app-card shadow-lg" >
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test5.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        I highly recommend Elegant Carve online marketing. They are great
                                        to work dedicatedly to result oriented. The website ranking has
                                        increased a lot thanks to their SEO program.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Sathish
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        Managing Director, SRS CAB
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-4/12 md:w-4/12 md:px-2 ">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/team-4-470x470.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        Wel,Elegant Carve Infotech pvt ltd Very customer satisfaction work. Good to work with your team. Happy with the Co ordination.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Chaithra M
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        CJS info solution
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >





             {/* Testimonials Mobile View*/}
             <section className="container mx-auto md:hidden block ">
                <h1 className="font-bold texthome text-blue text-3xl text-center md:mt-16 px-12 ">
                    Our Happy Customers
                    <p className="font-normal head1 text-base text-black pt-2 ">
                        Here is an appreciation to our work from so many happy customers, we really motivated by those words
                        from our satisfied customers.
                    </p>
                </h1>
                <div className="w-full lg:w-12/12 md:w-12/12 pt-4 px-2">
                    <div className="flex flex-wrap center">
                        <div className="lg:w-4/12 md:w-4/12 md:px-2 py-4">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test1.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        My new site designed by Elegant Carve Infotech. They've done a great job of creating
                                        website what I expected. Easy to work with and very responsive. They met all of my
                                        expectations and make us happy, I really existed. Thank yo so much!.

                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Deepthi Srinivas
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        CEO, IK Softec
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-4/12 md:w-4/12 md:px-2 py-2">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test3.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        The Elegant Carve did a fantastic work creating a website for DSPRO
                                        Films &amp; Sounds. They were friendly and nothing was too much trouble.
                                        A Creative design and on time delivery, we would highly recommend and we
                                        will continue to work with this team.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Dhinakar
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        Actor & Producer, DSPRO
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-4/12 md:w-4/12 md:px-2 py-2">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test2.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        They Never stop unless the client is satisfied, It’s a very good Web design and developing
                                        company. project submitted in a particular time and date. The Elegant Carve team guided
                                        us with current trends and helped us with smart content.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Srinivasa Rao Muddana
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        CEO, RJS Quality Cleaning Services
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >


                <div className="w-full lg:w-12/12 md:w-12/12 md:mt-4 px-2 md:pb-32">
                    <div className="flex flex-wrap center">
                        <div className="lg:w-4/12 md:w-4/12 md:px-2 py-4">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test4.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        We are really impressed with the level of development we
                                        received from Elegant Carve Website Design. We are so happy with our
                                        website design and ongoing online marketing results.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Suresh Kumar
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        Founder, Purumara Group
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-4/12 md:w-4/12 md:px-2 py-2">
                            <div className="app-card shadow-lg" >
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/imgs/test5.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        I highly recommend Elegant Carve online marketing. They are great
                                        to work dedicatedly to result oriented. The website ranking has
                                        increased a lot thanks to their SEO program.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Sathish
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        Managing Director, SRS CAB
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-4/12 md:w-4/12 md:px-2 pt-2 pb-12">
                            <div className="app-card shadow-lg">
                                <div className="wrapper1 md:pb-10 md:-mt-24">
                                    <img
                                        className="w-16 rounded-full "
                                        src={require("assets/img/team-4-470x470.png").default}
                                        alt="..."
                                    />
                                </div>
                                <div className="wrapper items-center">
                                    <p className="head1 text-sm text-left">
                                        Wel,Elegant Carve Infotech pvt ltd Very customer satisfaction work. Good to work with your team. Happy with the Co ordination.
                                    </p>
                                    <h1 className="font-bold text-blue text-left pt-2">
                                        Chaithra M
                                    </h1>
                                    <p className="head1 text-xs text-left">
                                        CJS info solution
                                    </p>
                                    <div className="text-left">
                                        <div class="clip-star"></div>
                                        <div class="clip-star "></div>
                                        <div class="clip-star"></div>
                                        <div class="clip-star"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <Footer />
        </>
    );
}