import React from "react";

export default function Footer() {


  return (
    <>
      <footer className="relative  pt-2  bottom-0">

        <section className="bg-white md:block hidden" >
          <div className="container w-full mx-auto lg:w-12/12 md:w-12/12 md:pt-6 md:pb-6">
            <div className="flex flex-wrap">
              <div className="lg:w-4/12 md:w-4/12 text-center justify-center ">
                <a href="https://www.facebook.com/elegantcarve2022" target="_blank">
                  <i className="text-blue fab fa-facebook text-2xl leading-lg px-4" />
                </a>
                <a href=" https://twitter.com/elegantcarve?lang=en" target="_blank">
                  <i className="text-blue fab fa-twitter text-2xl leading-lg px-4" />
                </a>
                <a href=" https://instagram.com/elegantcarvetechnologies?igshid=YmMyMTA2M2Y=" target="_blank">
                  <i className="text-blue fab fa-instagram-square text-2xl leading-lg px-4" />
                </a>
                <a href=" https://in.linkedin.com/company/elegantcarve" target="_blank">
                  <i className="text-blue fab fa-linkedin text-2xl leading-lg px-4" />
                </a>
                <a href=" https://in.pinterest.com/elegantcarve/" target="_blank">
                  <i className="text-blue fab fa-pinterest text-2xl leading-lg px-4" />
                </a>
              </div>
              <div className="lg:w-1/12 md:w-1/12">
              </div>
              <div className="lg:w-7/12 md:w-7/12 center">
                <h1 className="head1 text-blue text-base pt-2 pl-2">
                  Sitemap
                  <span className="px-4">Terms</span>
                  <span className="px-2">Privacy Notice</span>
                  <span className="px-2">Cookie Notice</span>
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray md:pb-12 md:block hidden">
          <div className="container mx-auto pt-12">
            <div className="w-full lg:w-12/12 md:w-12/12">
              <div className="flex flex-wrap pl-10 ">
                <div className="lg:w-3/12 md:w-3/12">
                  <h1 className="head1 text-blue text-lg font-bold">
                    Services
                  </h1>
                  <p className="head1 text-sm text-black pt-1">
                    Web Design
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Web Development
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Marketing
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    App Development
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Branding
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    E-Commerce
                  </p>

                  <h1 className="head1 text-blue text-lg font-bold pt-6">
                    About
                  </h1>
                  <p className="head1 text-sm text-black pt-1">
                    About Us
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Team
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Portfolio
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Testimonials
                  </p>

                </div>
                <div className="lg:w-3/12 md:w-3/12 ">
                  <h1 className="head1 text-blue text-lg font-bold">
                    Industries
                  </h1>
                  <p className="head1 text-sm text-black pt-1">
                    Education
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Automobile
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Recruitment
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Oil
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    E-Commerce
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Communications
                  </p>



                  <p className="head1 text-sm text-black pt-12">
                    Accounting
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Banking
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Medical
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Home Care
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Consumer Goods
                  </p>
                </div>
                <div className="lg:w-3/12 md:w-3/12 pt-8">
                  <h1 className="head1 text-black text-xl font-bold">

                  </h1>
                  <p className="head1 text-sm text-black pt-1">
                    Organization
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Health
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Insurances
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Garments
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Agriculture
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Transport & Logistics
                  </p>


                  <p className="head1 text-sm text-black pt-12">
                    Gift Shop
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Cakes
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Painting
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Fragrance
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Consultancy
                  </p>
                </div>



                <div className="lg:w-3/12 md:w-3/12">
                  <h1 className="head1 text-blue text-lg font-bold">
                    About Elegant Carve
                  </h1>
                  <p className="head1 text-sm text-black pt-2">
                    Novel Tech Park, #46/4,
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Kudlu Gate, Hosur Rd, HSR Extension,
                  </p>
                  <p className="head1 text-sm text-black pt-1">
                    Bengaluru, Karnataka 560068.
                  </p>
                  <p className="head1 text-base font-semibold text-blue pt-2">
                    Mob No : <span className="head1 text-sm text-black font-normal">
                      +91 9036323422
                    </span>
                  </p>
                  <p className="head1 text-base font-semibold text-blue pt-2">
                    Email Us: <span className="head1 text-sm text-black font-normal">
                      info@elegantcarve.com
                    </span>
                  </p>

                  <p className="head1 text-sm text-black pt-6">
                    <img
                      className="w-36"
                      src={require("assets/img/logo.png").default}
                      alt="..."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <h1 className="px-2 text-center text-blue py-4">©2024 <span className="font-bold">Elegant Carve</span>, All Rights Reserved</h1>
        </section>



        {/* Mobile View */}
        <section className="bg-white md:hidden block" >
          <div className="container w-full mx-auto lg:w-12/12 md:w-12/12 pt-6 pb-6">
            <div className="flex flex-wrap text-center">
              <div className="lg:w-4/12 md:w-4/12 text-center justify-center ">
                <a href="https://www.facebook.com/elegantcarve2022" target="_blank">
                  <i className="text-blue fab fa-facebook text-2xl leading-lg px-4" />
                </a>
                <a href="https://twitter.com/elegantcarve?lang=en" target="_blank">
                  <i className="text-blue fab fa-twitter text-2xl leading-lg px-4" />
                </a>
                <a href=" https://instagram.com/elegantcarvetechnologies?igshid=YmMyMTA2M2Y=" target="_blank">
                  <i className="text-blue fab fa-instagram-square text-2xl leading-lg px-4" />
                </a>
                <a href="https://in.linkedin.com/company/elegantcarve" target="_blank">
                  <i className="text-blue fab fa-linkedin text-2xl leading-lg px-4" />
                </a>
                <a href=" https://in.pinterest.com/elegantcarve/" target="_blank">
                  <i className="text-blue fab fa-pinterest text-2xl leading-lg px-4" />
                </a>
              </div>
              <div className="lg:w-1/12 md:w-1/12">
              </div>
              <div className="lg:w-7/12 md:w-7/12 center">
                <h1 className="head1 text-blue text-base pt-2 pl-2">
                  Sitemap
                  <span className="px-4">Terms</span>
                  <span className="px-2">Privacy Notice</span>
                  <span className="px-2">Cookie Notice</span>
                </h1>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}
