/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import bgelegantcarve from "assets/img/bgelegantcarve.png"


export default function Technologies() {
    return (
        <>
            <IndexNavbar fixed />
            <section className=" container mx-auto header relative justify-center items-center  let">
            </section>
            <section className="bgblues ">
                <section className="container mx-auto md:pt-32 ">
                    <div className="md:pb-12">
                        <div className="w-full lg:w-12/12 md:w-12/12">
                            <div className="flex flex-wrap md:py-12 p-4">
                                <div className="lg:w-6/12 md:w-6/12 md:pt-12 pl-10">
                                    <h1 className="texts font-extrabold text-5xl ">
                                        Build more,
                                        <h1 className="pt-2 ">
                                            Be More!
                                        </h1>
                                    </h1>
                                    <p className="text-base font-semibold pt-6">
                                        While Designing your website we also concentrate towards the <br></br>upgraded and latest trends
                                        and technology,through that we could <br></br>reach your satisfaction. Here some technology use
                                        in website development.
                                    </p>
                                    <div className="mt-12">
                                        <a
                                            href="https://www.creative-tim.com/learning-lab/tailwind/react/overview/notus?ref=nr-index"
                                            target="_blank"
                                            className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-buttongreen active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                        >
                                            Explore Our Services
                                        </a>
                                    </div>
                                </div>
                                <div className="lg:w-6/12 md:w-6/12 ">
                                    <img
                                        className=""
                                        src={require("assets/img/techsample.png").default}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            {/* LOGO SLIDER */}
            <section className="container mx-auto  relative md:mt-10  ">
                <div className="center ">
                </div>
                <h1 className="head1 font-bold text-4xl text-center pt-6 ">
                    Our Technologies
                </h1>
            </section>

            <h2 className="md:mt-10">
                <logo-slider >
                    <div className="px-2 ">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-bold text-xl text-black">
                                            ReactJS
                                        </h2>
                                        <p className="head1 text-sm">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/react.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>

                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-bold text-xl text-black">
                                            Laravel
                                        </h2>
                                        <p className="head1 text-sm">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/laravel.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>

                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-bold head1 text-xl text-black">
                                            CodeIgniter
                                        </h2>
                                        <p className="head1 text-sm pt-2 cardcolor font-normal">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/codeigniter.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>

                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-extrabold text-xl text-black">
                                            PHP
                                        </h2>
                                        <p className="head1 text-sm">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/php2.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>

                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-extrabold text-xl text-black">
                                            WordPress
                                        </h2>
                                        <p className="head1 text-sm">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/wordpress.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>
                </logo-slider>
            </h2>



            {/* LOGO SLIDER */}
            <h2 className="pb-16">
                <logo-slider2>
                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-extrabold text-xl text-black">
                                            HTML
                                        </h2>
                                        <p className="head1 text-sm">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/html.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>

                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-bold text-xl text-black">
                                            CSS
                                        </h2>
                                        <p className="head1 text-sm">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/css.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>

                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-bold head1 text-xl text-black">
                                            Mysql
                                        </h2>
                                        <p className="head1 text-sm pt-2 cardcolor font-normal">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/mysql.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>

                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-bold text-xl text-black">
                                            Sass
                                        </h2>
                                        <p className="head1 text-sm">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6 w-full"
                                            src={require("assets/img/sass.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>

                    <div className="px-2">
                        <h1 className="actions-template-card rounded  px-2">
                            <h1 className="w-full lg:w-12/12 md:w-12/12 border-b">
                                <h1 className="flex flex-wrap p-4">
                                    <h1 className="lg:w-8/12 md:w-8/12 ">
                                        <h2 className="font-extrabold text-xl text-black">
                                            Tailwind Css
                                        </h2>
                                        <p className="head1 text-sm">
                                            Customized Web Development for all kind of organizations, who
                                            are all looking for perfect professional services.
                                        </p>
                                    </h1>
                                    <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                                        <img
                                            className="pt-6"
                                            src={require("assets/img/tailwind.png").default}
                                            alt="..."
                                        />
                                    </h1>
                                </h1>
                            </h1>
                        </h1>
                    </div>
                </logo-slider2>
            </h2>


            <Footer />
        </>
    )
}