/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";



export default function Webdesign() {
  return (
    <>
      <IndexNavbar1 fixed />
      <section className="header relative pt-16 items-center flex ">
      </section>
      {/* 1nd Section (WEB DESIGN)*/}

      <div className="w-full lg:w-12/12 md:w-12/12 lg:pt-12 md:pb-12 md:block hidden">
        <div className="flex flex-wrap justify-center lg:px-32">
          <div className="lg:w-6/12 md:w-12/12">
            <div className="flex flex-wrap  ">
              <div className=" md:py-10 md:mt-10 lg:px-0 md:px-12 lg:pl-10">
                <h1 className="texthome py-2 text-4xl">
                  Best Customized<span className="font-extrabold text-blue pb-4 px-2">Web Designing</span> Service For You
                </h1>
                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                  Elegant Carve has climbed its way to the very top and secured itself as the best web design company.
                  Right from then our goal has been to craft high-quality websites for our clients, and be by their
                  side at every step of the process.
                </p>
                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                  The customer-oriented approach that we deem supremely important, helps us to model
                  websites in a manner that encourages user engagement.
                </p>
                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                  With our robust consulting & web designing service We focus on providing the best user experience
                  and increase engagement time with our advanced web designing service methodologies. The code is
                  tested post-designing to ensure it is bug-free and optimized to enhance the overall performance of the website.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/12 md:w-12/12  md:pt-20 pl-10">
          </div>

          <div className="lg:w-3/12 md:w-6/12  lg:pt-20 pl-10 justify-center items-center">
            <img
              className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white mt-6 md:ml-14"
              src={require("assets/img/imgs/wordpressdesign.png").default}
              alt="Website design companies in Bangalore"
            />
            <img
              className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white "
              src={require("assets/img/imgs/htmldesign.png").default}
              alt="Top web design and digital marketing company in Bangalore"
            />
            <img
              className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white md:ml-14"
              src={require("assets/img/imgs/reactdesign.png").default}
              alt="Affordable web design companies in Bangalore"
            />
          </div>
          <div className="lg:w-2/12 md:w-2/12  md:pt-20 pl-10">
          </div>
        </div>
      </div>


      {/* Mobile View (WEB DESIGN)*/}

      <div className="w-full lg:w-12/12 md:w-12/12 pt-12 pb-12 md:hidden block">
        <div className="flex flex-wrap justify-center md:px-32">
          <div className="lg:w-6/12 md:w-6/12">
            <div className="flex flex-wrap  ">
              <div className=" md:py-10  px-2">
                <h1 className="texthome py-2 text-2xl pl-2">
                  Best Customized<span className="font-extrabold text-blue pb-4 px-2">Web Designing</span> Service For You
                </h1>
                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                  Elegant Carve has climbed its way to the very top and secured itself as the best web design company.
                  Right from then our goal has been to craft high-quality websites for our clients, and be by their
                  side at every step of the process.
                </p>
                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                  The customer-oriented approach that we deem supremely important, helps us to model
                  websites in a manner that encourages user engagement.
                </p>
                <p className="head1 text-base pt-2 font-normal text-black pl-2">
                  With our robust consulting & web designing service We focus on providing the best user experience
                  and increase engagement time with our advanced web designing service methodologies. The code is
                  tested post-designing to ensure it is bug-free and optimized to enhance the overall performance of the website.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/12 md:w-1/12 px-4">
          </div>

          <div className="lg:w-3/12 md:w-3/12  pt-6 px-4 justify-center items-center">
            <img
              className="color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white mt-6 md:ml-14"
              src={require("assets/img/imgs/wordpressdesign.png").default}
              alt="Website design companies in bangalore"
            />
            <img
              className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white "
              src={require("assets/img/imgs/htmldesign.png").default}
              alt="Top web design and digital marketing company in Bangalore"
            />
            <img
              className="mt-6 color-bg-default box-shadow-card-border-mktg rounded-lg text-left p-2 p-lg-3 bg-white md:ml-14"
              src={require("assets/img/imgs/reactdesign.png").default}
              alt="Affordable web design companies in Bangalore"
            />
          </div>
          <div className="lg:w-2/12 md:w-2/12  md:pt-20 pl-10">
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}