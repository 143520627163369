/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// components


export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [downOpen, setdownOpen] = React.useState(false);
  const [dropOpen, setdropOpen] = React.useState(false);
  const [dropdownOpen, setdropdownOpen] = React.useState(false);

  return (
    <>
      <header className="md:block hidden">
        <nav id="mega_menu" className=" top-0 ">
        <div className="fixed z-50 w-full flex flex-wrap shadow items-center justify-between px-2  g-transparent">
            <div className="container px-4 mx-auto justify-between py-2">
              <div className="items-center flex justify-between ">
                <Link to="/index" className="lg:w-5/12 md:w-3/12 w-6/12">
                  <img className="w-52"
                    src={require("assets/img/EClogo.png").default}
                    alt="App Devleopment Company in Bangalore"
                    >
                  </img>
                </Link>
                <div className="items-center">
                  <ul className="">
                    <li className="dropdown  "><a href="/services ">
                      <h1 className="head1 font-semibold text-base -mt-4">Services
                        <i className="fas fa-caret-down pl-2 text-sm " ></i></h1>
                    </a>
                      <div id="mega">
                        <div className="w-full lg:w-12/12 md:12/12">
                          <div className="flex flex-wrap">
                            <div className="pl-4 lg:w-6/12 md:w-6/12 border-r-2">
                              <ul class="">
                                <li>
                                  <div className="flex flex-wrap">
                                    <div className="lg:w-3/12 md:w-3/12  ">
                                      <img
                                        className="w-20"
                                        src={require("assets/img/imgs/WEBDESIGN.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12 pt-2">
                                      <a href="/webdesign">
                                        <h1 className="font-bold text-sm  head1 text-charcoal500">
                                          Web Design
                                        </h1>
                                        <p className="text-xs text-charcoal400 head1 pt-2">
                                          We are deliver a high quality services and success for any business.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="flex flex-wrap pt-6">
                                    <div className="lg:w-3/12 md:w-3/12  ">
                                      <img
                                        className=" w-20"
                                        src={require("assets/img/imgs/WEBDEVELOPMENT.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12 pt-2">
                                      <a href="/webdevelop">
                                        <h1 className="font-bold text-sm head1 text-charcoal500">
                                          Web Development
                                        </h1>
                                        <p className="text-xs text-charcoal400 pt-2 head1">
                                          We Develop your website with the latest trends based on your needs.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="flex flex-wrap pt-6 ">
                                    <div className="lg:w-3/12 md:w-3/12  ">
                                      <img
                                        className="w-20"
                                        src={require("assets/img/imgs/MARKETING.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12 pt-2">
                                      <a href="/marketing">
                                        <h1 className="font-bold text-sm head1 text-charcoal500">
                                          Marketing
                                        </h1>
                                        <p className="text-xs text-charcoal400 pt-2">
                                          Our team understands the need of our clients to generate better results.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="lg:w-6/12 md:w-6/12">
                              <ul class="">
                                <li>
                                  <div className="flex flex-wrap pl-4">
                                    <div className="lg:w-3/12 md:w-3/12  ">
                                      <img
                                        className="w-20"
                                        src={require("assets/img/imgs/APPDEVELOPMENT.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12 pt-2">
                                      <a href="/appdevelop">
                                        <h1 className="font-bold text-sm  head1 text-charcoal500">
                                          App Development
                                        </h1>
                                        <p className="text-xs text-charcoal400 pt-2">
                                          This is a functional piece of software driven by dynamic and interactive.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                                {/*<li>
                                <div className="flex flex-wrap pt-4 pl-4">
                                <div className="lg:w-3/12 md:w-3/12  ">
                                    <img
                                      className="rounded-lg"
                                      src={require("assets/img/imgs/BRANDING.png").default}
                                      alt="..."
                                    />
                                  </div>
                                  <div className="lg:w-7/12 md:w-7/12 pt-2">
                                    <a href="/">
                                      <h1 className="font-bold text-sm  head1 text-charcoal500">
                                        Branding
                                      </h1>
                                      <p className="text-xs text-charcoal400 pt-2">
                                        Your business is our passion to deliver the right solution for your needs.
                                      </p>
                                    </a>
                                  </div>
                                </div>
  </li>*/}
                                <li>
                                  <div className="flex flex-wrap pt-4 pl-4">
                                    <div className="lg:w-3/12 md:w-3/12  ">
                                      <img
                                        className="rounded-lg"
                                        src={require("assets/img/imgs/ECOMMERCE.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12 pt-2">
                                      <a href="/ecommerce">
                                        <h1 className="font-bold text-sm  head1 text-charcoal500">
                                          E-Commerce
                                        </h1>
                                        <p className="text-xs text-charcoal400 pt-2 hover:text-lightBlue-600:hover">
                                          We will technology establish effective connect with your target audience.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="dropdown  ">
                      <h1 className="head1 font-semibold text-base  px-4">About
                        <i className="fas fa-caret-down pl-2 text-sm " ></i></h1>

                      <div id="mega">
                        <div className="w-full lg:w-12/12 md:12/12 ">
                          <div className="flex flex-wrap">
                            <div className="pl-2 lg:w-6/12 md:w-6/12 border-r-2">
                              <ul class="">
                                <li>
                                  <div className="flex flex-wrap">
                                    <div className="lg:w-3/12 md:w-3/12 ">
                                      <img
                                        className="W-20"
                                        src={require("assets/img/imgs/ABOUTUS.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12 pt-2 ">
                                      <a href="/about ">
                                        <h1 className="font-bold text-sm  head1 text-charcoal500">
                                          About Us
                                        </h1>
                                        <p className="text-xs text-normal text-charcoal400 head1 pt-2">
                                          We are most recognized and customers favored website design company in bangalore.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="flex flex-wrap pt-6">
                                    <div className="lg:w-3/12 md:w-3/12 ">
                                      <img
                                        className="w-20"
                                        src={require("assets/img/imgs/TEAMS.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12 pt-2">
                                      <a href="/about">
                                        <h1 className="font-bold text-sm head1 text-charcoal500">
                                          Team
                                        </h1>
                                        <p className="text-xs text-normal text-charcoal400 head1 pt-2">
                                          Our team will do each task better and faster to complete your work.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="lg:w-6/12 md:w-6/12">
                              <ul class="">
                                <li>
                                  <div className="flex flex-wrap pl-4">
                                    <div className="lg:w-3/12 md:w-3/12 ">
                                      <img
                                        className="w-20"
                                        src={require("assets/img/imgs/PORTFOLIO.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12 pt-2">
                                      <a href="/works">
                                        <h1 className="font-bold text-sm  head1 text-charcoal500">
                                          Our Works
                                        </h1>
                                        <p className="text-xs text-charcoal400 pt-2">
                                          We could help you in different areas like website design, logo design and brochure design

                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="flex flex-wrap pl-4 pt-8">
                                    <div className="lg:w-3/12 md:w-3/12 ">
                                      <img
                                        className="rounded-lg"
                                        src={require("assets/img/imgs/TESTIMONIALS.png").default}
                                        alt="..."
                                      />
                                    </div>
                                    <div className="lg:w-7/12 md:w-7/12">
                                      <a href="/testimonial">
                                        <h1 className="font-bold text-sm  head1 text-charcoal500">
                                          Testimonials
                                        </h1>
                                        <p className="text-xs text-charcoal400 pt-2">
                                          Here is an appreciation to our work from so many happy customers,
                                          we really motivated by those words from our statisfied customers.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="px-3 border-l-2 border-lightBlue-700 ">
                      <div className="flex items-center">
                        <img
                          alt="..."
                          src={require("assets/img/indiaflag.png").default}
                          className=" w-6"
                        />
                        <h1 className="font-semibold pl-1 text-sm">
                          IND
                        </h1>
                      </div>

                    </li>



                    <li className=" px-4 border-l-2 border-lightBlue-700 ">
                      <button className="cursor-pointer lg:static lg:text-lg  text-xl  right-0 border-l lg:border-0 rounded outline-none focus:outline-none"
                        type="button"
                        onClick={() => setdownOpen(!downOpen)}>
                        <div className="flex">
                          <i className="fa fa-globe text-sm"></i>
                          <h1 className="font-semibold pl-1 text-sm">
                            IN - EN
                          </h1>
                          {/*<i class="fas fa-caret-down pl-1" ></i>*/}
                        </div>
                      </button>


                      {/* Dropdown flag */}
                      {/* <div
                    className={
                      " flex lg:w-auto  " +
                      (downOpen ? " block " : " hidden")
                    }
                  >
                    <button className="fixed inset-0 cursor-default"
                      onClick={(e) => {
                        setdownOpen(false); setNavbarOpen(false);
                      }}>
                    </button>
                    <ul className="bg-white shadow-lg absolute right-20  w-48 md:mt-10 flex flex-col list-none"
                      onClick={(e) => {
                        setdownOpen(false); setNavbarOpen(false);
                      }}>
                      <li className="flex items-center border-b py-1">
                        <a href="/about">
                          <div className="font-2xl  flex">
                            <i className="fa fa-globe pt-4 pl-1 text-black"></i>
                            <h1 className="font-bold pl-2  text-black">
                              UAE - AE
                            </h1>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>*/}
                    </li>
                    <a href="tel:+91 9036323422">
                      <li className=" px-4 border-l-2 border-lightBlue-700 ">
                        <i className="fa fa-phone text-sm"></i>
                      </li>
                    </a>
                    <a href="/contact">
                      <li className=" px-4 border-l-2 border-lightBlue-700 ">
                        <i className="fa fa-user text-sm"></i>
                      </li>
                    </a>


                  </ul>
                </div>
              </div>
            </div>
          </div>

        </nav>
      </header>








      {/* Mobile View */}

      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg bg-white md:hidden block ">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a href="/index">
              <img className="w-8/12"
                src={require("assets/img/EClogo.png").default}
                alt="Bangalore webdevelopment company"
                >
              </img>
            </a>
            <div id="menuToggle1">
              <input type="checkbox" />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu1">
                <div className="border-b">
                  <li className="flex items-center relative ">
                    <button className="cursor-pointer lg:static lg:text-lg lg:pr-2 lg:pl-0 lg:py-1 text-3xl text-white absolute left-0 py-2 border-l lg:border-0 rounded outline-none focus:outline-none"
                      type="button"
                      onClick={() => setdropOpen(!dropOpen)}>
                      <div className="flex">
                        <h1
                          className="pr-4 flex items-center text-base uppercase font-bold pb-4">
                          About
                        </h1>
                        <i class="fas fa-caret-down pb-4" ></i>
                      </div>
                    </button>
                    <div
                      className={
                        " flex lg:w-auto  " +
                        (dropOpen ? " block " : " hidden")
                      }
                    >
                      <ul className="absolute right-0 left-0 top-16 lg:w-80 lg:mt-0 px-8  z-50 lg:relative lg:px-2 lg:rounded-md flex flex-col list-none"
                        onClick={(e) => {
                          setdropOpen(false); setNavbarOpen(false);
                        }}>


                        <li className="flex items-center border-b py-1 mt-2">
                          <a href="/about"
                            className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                          >
                            About Us
                          </a>
                        </li>
                        <li className="flex items-center border-b py-1">
                          <a href="/works"
                            className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                          >
                            Our Works
                          </a>
                        </li>
                        <li className="flex items-center border-b py-1">
                          <a href="/testimonials"
                            className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                          >
                            Testimonials
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>
                <div className="border-b">
                  <div
                    className={
                      " flex w-full " +
                      (dropOpen ? " pt-32 " : " pt-0")
                    }
                  >
                    <li className="flex items-center relative ">
                      <Link
                        to="/services"
                        className=" pr-4 py-4 lg:py-2  items-center text-base uppercase font-bold">
                        Services
                      </Link>
                      <button className="cursor-pointer lg:static lg:text-lg  lg:py-1 text-3xl text-white absolute right-0 py-2 lg:border-0 rounded outline-none focus:outline-none"
                        type="button"
                        onClick={() => setdropdownOpen(!dropdownOpen)}>
                        <i class="fas fa-caret-down pl-6" ></i>
                      </button>
                      <div
                        className={
                          " flex lg:w-auto  " +
                          (dropdownOpen ? " block " : " hidden")
                        }
                      >
                        <ul className="fixed left-0 right-0 pl-12 top-16 w-12/12 lg:mt-0 z-50 lg:relative lg:px-2 lg:rounded-md flex flex-col list-none"
                          onClick={(e) => {
                            setdropdownOpen(false); setNavbarOpen(false);
                          }}>

                          <li className="flex items-center border-b py-1 mt-8">
                            <a href="/webdesign"
                              className="hover:text-white text-white lg:py-1  items-center text-base  font-semibold bg-transparent"
                            >
                              Web Design
                            </a>
                          </li>
                          <li className="flex items-center border-b py-1">
                            <a href="/webdevelop"
                              className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                            >
                              Web Development
                            </a>
                          </li>
                          <li className="flex items-center border-b py-1">
                            <a href="/appdevelop"
                              className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                            >
                              App Development
                            </a>
                          </li>
                          <li className="flex items-center border-b py-1">
                            <a href="/marketing"
                              className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                            >
                              Marketing
                            </a>
                          </li>
                          <li className="flex items-center border-b py-1">
                            <a href="/ecommerce"
                              className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                            >
                              E-Commerce
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </div>
                </div>
                <div className="border-b">
                  <div
                    className={
                      " flex lg:w-auto  " +
                      (dropdownOpen ? " pt-52" : " pt-0")
                    }
                  >
                    <li className="flex items-center ">
                      <Link
                        to="/contact"
                        className="  py-2 lg:py-2 flex items-center text-base uppercase font-bold"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
