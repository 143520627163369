/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar1 from "components/Navbars/IndexNavbar1.js";
import Footer from "components/Footers/Footer.js";
import bgelegantcarve1 from "assets/img/bgelegantcarve1.png"


export default function Index() {
  return (
    <>
      <IndexNavbar1 fixed />

      <section className=" container mx-auto header relative justify-center items-center md:pt-32 pt-12 let">
      </section>
      <section className="w-full mx-auto h-350-px md:block hidden" >
        <section className="container mx-auto md:pt-12 relative ">
          <h1 className="texthome text-4xl text-center font-extrabold  text-blue">
            About Us
          </h1>

          <div className="">
            <p className="head1 text-lg  text-center lg:px-32 md:px-12 text-charcoal400 pt-2">
              Elegant Carve Technologies is on of the most recognized and customers favored website design company in Bangalore.
              Which provides you a quality work without any compromise so that we could reach your expectations with well mannered.
              Here are some services which successfully delivered to the customer
            </p>
          </div>
        </section>
      </section>


      {/* Mission Vision */}

      <section className="container mx-auto md:pb-6 lg:-mt-30 md:-mt-24 md:block hidden">
        <div className="w-full lg:w-12/12 md:w-12/12 lg:px-12">
          <div className="row ">
            <div className="md:w-4/12 lg:w-4/12  px-2">
              <div className="pricing_inner pricing_blue_bg py-24">
                <div className="md:px-6">
                  <h1 className="head1 text-xl font-bold md:-mt-16 text-blue">
                    Our Vision
                  </h1>
                  <p className="head1 text-base pt-2">
                    We provide innovative, consumer-led, adaptable software to build solutions that offer your audience an impeccable, user-friendly, and fascinating digital experience driven by dynamic digital experience driven by dynamic.
                  </p>

                </div>
              </div>
            </div>
            <div className="md:w-4/12 lg:w-4/12  px-2">
              <div className="pricing_inner pricing_red_bg py-24">
                <div className="md:px-6">
                  <h1 className="head1 text-xl font-bold md:-mt-16 text-red">
                    Our Mission
                  </h1>
                  <p className="head1 text-base pt-2">
                    We are deliver a high quality services and success for any business. Our designs create websites that ensure an enjoyable user experience, be it in visuality or in functionality be it in visuality or in functionality.
                  </p>

                </div>
              </div>
            </div>
            <div className="md:w-4/12 lg:w-4/12  px-2">
              <div className="pricing_inner pricing_green_bg py-24">
                <div className="md:px-6">
                  <h1 className="head1 text-xl font-bold md:-mt-16 text-green">
                    Our Value
                  </h1>
                  <p className="head1 text-base pt-2">
                    Our Team Understands the need of our clients to generate better results. The great aspect of social media is that a lot of the potential customers are there. We creating brand awareness, having quality leads.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section*/}

      {/*<section className="bg-white md:block hidden">
        <div className="container mx-auto">
          <h1 className="texthome text-center font-bold text-4xl text-blue lg:pt-12">
            Elegant Carve
          </h1>
          <div className="  md:pr-12">
            <img
              className=""
              src={require("assets/img/imgs/teamfullimage.png").default}
              alt="..."
            />
          </div>
        </div>
  </section>*/}



      {/*Mobile View */}

      <section className="w-full mx-auto h-500-px md:hidden block" >
        <section className="container mx-auto pt-24 relative ">
          <h1 className="texthome text-4xl text-center font-extrabold  text-blue">
            About Us
          </h1>
          <div className="">
            <p className="head1 text-base  text-center px-4 text-charcoal400 pt-2">
              Elegant Carve Technologies is on of the most recognized and customers favored website design company in Bangalore.
              Which provides you a quality work without any compromise so that we could reach your expectations with well mannered.
              Here are some services which successfully delivered to the customer
            </p>
          </div>
        </section>
      </section>
      {/* Mission Vision */}
      <section className="container mx-auto md:pb-6 -mt-32 md:hidden block">
        <div className="w-full lg:w-12/12 md:w-12/12 md:px-12">
          <div className="row ">
            <div className="md:w-4/12 lg:w-4/12  px-4">
              <div className="pricing_inner pricing_blue_bg pt-12 pb-12">
                <div className="px-4">
                  <h1 className="head1 text-xl font-bold -mt-2 text-blue">
                    Our Vision
                  </h1>
                  <p className="head1 text-sm pt-2">
                    We provide innovative, consumer-led, adaptable software to build solutions that offer your audience an impeccable, user-friendly, and fascinating digital experience driven by dynamic digital experience driven by dynamic.
                  </p>
                  <div className="pt-6">
                    <a href="/services" target="_blank" rel="noreferrer" className="w-full justify-center border-lightBlue-700 py-2 rounded font-bold leading-4 flex items-end text-center box-border text-charcoal500 border ">
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-4/12 lg:w-4/12  px-4">
              <div className="pricing_inner pricing_red_bg pt-12 pb-12">
                <div className="px-4">
                  <h1 className="head1 text-xl font-bold -mt-2 text-red">
                    Our Mission
                  </h1>
                  <p className="head1 text-sm pt-2">
                    We are deliver a high quality services and success for any business. Our designs create websites that ensure an enjoyable user experience, be it in visuality or in functionality be it in visuality or in functionality.
                  </p>
                  <div className="pt-6">
                    <a href="/services" target="_blank" rel="noreferrer" className="w-full justify-center border-lightBlue-700 py-2 rounded font-bold leading-4 flex items-end text-center box-border text-charcoal500 border ">
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-4/12 lg:w-4/12  px-4">
              <div className="pricing_inner pricing_green_bg pt-12 pb-12">
                <div className="px-4">
                  <h1 className="head1 text-xl font-bold -mt-2 text-green">
                    Our Value
                  </h1>
                  <p className="head1 text-sm pt-2">
                    Our Team Understands the need of our clients to generate better results. The great aspect of social media is that a lot of the potential customers are there. We creating brand awareness, having quality leads.
                  </p>
                  <div className="pt-6">
                    <a href="/services" target="_blank" rel="noreferrer" className="w-full justify-center border-lightBlue-700 py-2 rounded font-bold leading-4 flex items-end text-center box-border text-charcoal500 border ">
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Team Section*/}
     {/* <section className="bg-white  md:hidden block">
        <div className="container mx-auto">
          <h1 className="texthome text-center font-bold text-4xl text-blue pt-6">
            Elegant Carve
          </h1>
          <div className=" pt-2 md:pr-12">
            <img
              className=""
              src={require("assets/img/imgs/teamfullimage.png").default}
              alt="..."
            />
          </div>
        </div>
  </section>*/}
      <Footer />
    </>
  )
}